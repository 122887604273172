exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-details-dontmove-js": () => import("./../../../src/pages/portfolio-details-dontmove.js" /* webpackChunkName: "component---src-pages-portfolio-details-dontmove-js" */),
  "component---src-pages-portfolio-details-js": () => import("./../../../src/pages/portfolio-details.js" /* webpackChunkName: "component---src-pages-portfolio-details-js" */),
  "component---src-pages-portfolio-details-mental-1-js": () => import("./../../../src/pages/portfolio-details-mental1.js" /* webpackChunkName: "component---src-pages-portfolio-details-mental-1-js" */),
  "component---src-pages-portfolio-details-social-js": () => import("./../../../src/pages/portfolio-details-social.js" /* webpackChunkName: "component---src-pages-portfolio-details-social-js" */),
  "component---src-pages-portfolio-details-teamleads-js": () => import("./../../../src/pages/portfolio-details-teamleads.js" /* webpackChunkName: "component---src-pages-portfolio-details-teamleads-js" */)
}

