import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Title, Text, Span } from "../../components/Core";
import imgPhoto from "../../assets/image/jpg/johannamain1.jpg";
// import imgSignLight from "../../assets/image/png/signature.png";
import imgAbout1 from "../../assets/image/png/socialcollage.png";
import imgAbout2 from "../../assets/image/png/Contentcollage.png";
import Contact2 from "../../sections/landing6/Contact-Button";
import { borderRadius, bottom, paddingLeft } from "styled-system";

const LinkSocial = styled.a`
  color: ${({ theme }) => theme.colors.light} !important;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 1.63px;
  transition: 0.4s;
  &:hover {
    color: ${({ theme }) => theme.colors.light} !important;
    transform: translateY(-5px);
  }
`;

const About = ({ hero = true, bg = "dark", ...rest }) => {
  return (
    <>
      <Section hero={hero} bg={bg} {...rest}>
        <Container>
          <Row className="align-items-center">
            { <Col lg="7">
              <div>
                <img src={imgPhoto} alt="folio" className="img-fluid" style={{ borderRadius: '60px', marginBottom:'20px' }}/>
              </div>
            </Col> }
            
            <Col lg="7">
              <div className="pl-lg-4 pt-5 pt-lg-0">
                <Title color="light" variant="secSm">
                  Johanna Arredondo
                </Title>
                <Text
                  color="light"
                  className="mt-3 mt-lg-5"
                  css={`
                    line-height: 1.5;
                  `}
                >
                  <p>What I do is write. I work in wordsmithing to forge a compelling narrative that can be used to promote a product, a brand, or simply to communicate. </p>
              
<p>It all began with stories. Fascinating, enthralling, magical stories. Throughout the years, I have only fallen deeper in love with storytelling, dedicating my every moment to improving, writing, and perfecting my abilities within the craft.</p>

<p>I’m far from being the only one to appreciate a tale well told. As social media grew and exploded, the masses began to crave content that spoke to them. Finding a message the audience could connect with, while transmitting value and inspiring curiosity, became a project I could undertake. Organic growth soon followed. </p></Text>
&nbsp;
{ <Col lg="11">
              <div>
                <img src={imgAbout1} alt="Social" className="img-fluid" />
              </div>
            </Col> }
            &nbsp;
                <Text>
<p>And yet, the inviting briefness of social media often demands the viewer is satisfied in their desire to know more. Clear, light, various blogs and articles could fulfill this need. At the same time, I focused on directing incoming traffic to connect with our hotel and tech industry brands and take action. I was granted endless opportunities to come up with creative ideas to create content that highlighted our organizations, products, and values.</p>

<p>At some point I found myself returning to a path I’d followed before I’d take on writing full-time: science. Thanks to my good old Bachelor’s degree in Biology, falling into the best practices to understand and critically analyze scientific research allowed me to draft short, and yet fully factual blogs that brought value to the reader.</p>

<p>But if engaging a wider audience covered the larger part of my career, creating content that focused on internal communications allowed me to explore the journalistic side of writing by interviewing subject matter experts and turning their input into easy-to-digest content.</p>
</Text>
&nbsp;
{ <Col lg="11">
              <div>
                <img src={imgAbout2} alt="Social" className="img-fluid" />
              </div>
            </Col> }
            &nbsp;
            <Text>
<p>At every turn, storytelling inspired my work. It is at the intersection of my audience’s and my brand’s story that I aim to thrive. Life is full of tales and it is my dream to continue to write a few of them myself.</p>

<p>If like me you see the value in using the power of narratives and words to drive forward a brand or product, to communicate, or educate, then I’d love nothing more than to hear from you. Perhaps we can work on something great together. </p>

                </Text>

                <div className="mt-4">
                  <Contact2/>
                </div>
                <div className="mt-5 d-flex">
                  <LinkSocial
                    href="https://www.linkedin.com/in/johannalarredondo/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mr-3"
                  >
                    Linkedin
                  </LinkSocial>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default About;
