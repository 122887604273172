export const menuItems = [
  {
    // name: "demos",
    // label: "creative demos",
    // items: [
    //   { name: "", label: "designer 01" },
    //   { name: "designer-2", label: "designer 02" },
    //   { name: "devloper-1", label: "devloper 01" },
    //   { name: "devloper-2", label: "devloper 02" },
    //   { name: "agency-1", label: "agency 01" },
    //   { name: "agency-2", label: "agency 02" },
    //   { name: "photography-1", label: "photography 01" },
    //   { name: "photography-2", label: "photography 02" },
    // ],
  }
];
